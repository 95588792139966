import React, { useState } from 'react';
import styled from 'styled-components';
import { Hero, SEO, Video, AXDemo, AXCases, RenderWhenReady } from 'components';
import { AxEngageLogo } from 'images/shared';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PrevArrow from 'images/shared/PrevArrow';

const VirtualEventsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        thumbnailImage: file(relativePath: { eq: "service-pages/studio.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    `
  );

  return (
    <>
      <SEO
        pageSpecificTitle="Events & Conferences"
        pageSpecificDescription="Expert design, planning and production management of all types of in-person, virtual & hybrid events and conferences by our specialist in house Agency X team."
      />
      <Hero>
        <HeroContent>
          <Directions>
            <StyledLink to="/#services">
              <BackButton>
                <StyledPrevArrow />
              </BackButton>
            </StyledLink>
            <StyledLink to="/services/healthcare-omnichannel-strategies">
              <NextButton>
                <StyledNextArrow />
              </NextButton>
            </StyledLink>
          </Directions>
          <h1>Events & Conferences</h1>
          <Text>
            <h2>Fully produced and managed In-Person, Virtual & Hybrid Events</h2>
            <p>
              From branding, design and content creation, to planning, organisation and full
              production management. Whether in-person or virtual, we can produce engaging,
              informative, and enjoyable event experiences for your audience.
            </p>
          </Text>
        </HeroContent>
      </Hero>
      <RenderWhenReady>
        <VideoSection style={{ paddingBottom: '1rem' }}>
          <p>2024 IPHA Annual Conference</p>
          <Video url="https://player.vimeo.com/video/921971950" />
        </VideoSection>
      </RenderWhenReady>
      <Section>
        <Subtitle>
          Our team have over 30 years of live broadcast experience and we put this behind every show
          we produce.
        </Subtitle>
        <Article uneven>
          <Column>
            <h2>
              Virtual & Hybrid
              <br /> Healthcare
              <br /> Events
            </h2>
          </Column>
          <Column>
            <Text>
              <AxEngageLogo />
              <p>
                AX ENGAGE is our bespoke platform created specifically for the healthcare sector, it
                is designed to connect HCPs, industry and patient groups at their conferences,
                learning events, or multi day symposia both national and international.
              </p>
            </Text>
          </Column>
        </Article>
      </Section>
      <RenderWhenReady>
        <VideoSection style={{ paddingBottom: '3rem' }}>
          <Video url="https://player.vimeo.com/video/715130118?h=4bd3c499d3" />
        </VideoSection>
      </RenderWhenReady>
      <Section>
        <Article>
          <Column>
            <VideoWrapper>
              <GatsbyImage image={getImage(data.thumbnailImage)} alt="Video thumbnail" />
              <video
                muted
                autoPlay
                loop
                playsInline
                id="Virtual & Hybrid Healthcare Events"
                preload="auto"
                controls={false}>
                <source src="/studio.mp4" type="video/mp4" />
              </video>
            </VideoWrapper>
          </Column>
          <Column>
            <p>
              Combining over{' '}
              <strong>
                20 years of broadcast production expertise with the latest technologies
              </strong>
              , our AX ENGAGE platform delivers a seamless, professional healthcare event experience
              designed to resonate effectively with your brand audience. As a{' '}
              <strong>virtual event hub</strong>
              especially for the <strong>Healthcare Sector</strong>, AX ENGAGE is{' '}
              <strong>
                fully secure, scalable, and entirely customisable for each individual client&apos;s
                needs.
              </strong>
            </p>

            <p>
              From 1-hour breakfast briefings to annual conferences and multiday symposia, our team
              will professionally design, produce and stream your show for maximum engagement with
              your Healthcare audience.
            </p>

            <p>
              AX ENGAGE is ideal for one off events, or a content series, and offers unique platform
              features and functions such as full social media integration, full compatibility with
              existing livestream platforms, Q&A functions, sponsor booths, and integrated polling.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <AXDemo />
      </Section>
      <Section>
        <h3 style={{ textTransform: 'uppercase' }}>Case Studies</h3>
        <p>
          Please find some of our recently produced events in our case study links below. All our
          projects are customised to the specific requirements of our clients and we will be
          delighted to share our experiences of best practice for your business event upon request.
        </p>
        <AXCases />
      </Section>
    </>
  );
};

const Directions = styled.div`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  width: 100%;
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const NextButton = styled(BackButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledNextArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledLink = styled(AnchorLink)`
  display: block;
  grid-column: 1;
  margin-top: -0.75em;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    grid-column: 1/3;
  }
`;

const Subtitle = styled.h2`
  grid-column: 2/3;
  margin: 0 auto;
  margin-bottom: 2em !important;
  font-size: 1.5rem;
  color: var(--ax-gold);
  font-weight: 800;
  text-align: center;
  max-width: 900px;
  svg {
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
  }
  @media (min-width: 48rem) {
    font-size: 2rem;
    text-align: center;
    svg {
      height: 1.3rem;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  p {
    line-height: 1.3em;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    max-height: 550px;
  }
  @media (min-width: 48rem) {
    font-size: 2rem;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-column: 1fr;
  grid-gap: 3rem;
  margin: 0 auto;
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  @media (min-width: 48rem) {
    h2 {
      font-size: 2.75rem;
    }
  }
  @media (min-width: 67.5em) {
    grid-gap: ${({ uneven }) => (uneven ? '2rem' : '5rem')};
    grid-template-columns: ${({ uneven }) => (uneven ? '7fr 6fr' : '1fr 1fr')};
  }
  @media screen and (min-width: 67.5rem) {
    h2 {
      font-size: 4rem;
    }
  }
  @media screen and (min-width: 82.5rem) {
    h2 {
      font-size: 4.5rem;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 2rem 0;
  margin: 0 auto;
  > div {
    grid-column: 2/3;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  > h2,
  h3 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  h3 {
    color: var(--ax-pink);
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem;
    h1 {
      font-size: 2.75rem;
    }
    > h2,
    h3 {
      font-size: 2rem;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 4.5rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 1rem;
  margin-bottom: 1rem;
  h2 {
    font-size: 2rem;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 300;
  }
  svg {
    height: 1.5rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  @media (min-width: 48em) {
    svg {
      align-self: flex-start;
    }
  }
  @media (min-width: 67.5em) {
    svg {
      height: 2.5rem;
    }
  }
`;

const VideoSection = styled.div`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 3rem 1rem;
  margin: 0 auto;
  p {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem;
    p {
      margin-left: calc(6.25% - 3rem);
    }
  }
  @media (min-width: 90rem) {
    p {
      margin-left: calc(9% - 3rem);
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    grid-column: span 2;
  }
  @media screen and (min-width: 48rem) {
    h1 {
      font-size: 2.75rem;
      grid-column: span 1;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 1180px;
    margin: 3rem auto 3rem;
    align-items: center;
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 4.5rem;
    }
  }
`;

export default VirtualEventsPage;
